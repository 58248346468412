import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        spip: {
            spip_id: '',
            spip_nama: '',
            spip_ket: '',
            spip_file: '',
            spip_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },
        viewModal(state, value) {
            state.spip = value
        }
    }
})
